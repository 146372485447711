.primary-card {
  background: #f6f4f0;
  border: 1px solid #e5e5e5;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  flex-basis: calc(20% - 10px); /* Adjust as needed */
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.primary-card .titleElement .title  {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}


.primary-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.primary-card__image-wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: 75%;
  background-color: #f5f5f5; /* Background color for the image area */
}

.primary-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.primary-card__info {
  padding: 14px;
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allow content to grow and fill remaining space */

}
  

