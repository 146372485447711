/* policies.component.css */

.policycontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
  }
  
  .title {
    margin: 10px;
    text-align: center;
  }
  
  .policybody {
    font-size: 16px;    line-height:1.8;

    color: #595844BF;

    text-align: left;
    max-width: 550px;
  }
  