  /* Content container */
  .footer-content {
    display: flex;
    flex-direction: row;
    max-width: 1120px;
    padding: 0 5rem;
    gap: 100px;
    margin: 40px auto;
  }

  /* Logo */
  .logo-container img {
    width: 100%;
    max-width: 200px;
    height: auto;
  }

  .quick-links .titleElement .title{
    margin: 0px;
  }

  .quick-links ul {
    list-style: none;
  }
  
  .quick-links ul li {

    margin-bottom: 10px;
  }
  
  .quick-links ul li a {
    text-decoration: none;
    color: #595844;
    letter-spacing : 0.6px;
    transition: color 0.3s ease;
    font-size: 14px;
    opacity: 0.7;
  }
  .quick-links ul{
    padding: 0px;
  }
  .quick-links ul li a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  
  /* Payment methods */
  .payment-methods ul {
    display: flex;
    list-style: none;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
    
  }
  

  
  .legal-info small {
    display: block;
    margin-bottom: 10px;
  }
  
  .legal-info ul {
    list-style: none;
    padding: 0px;
  }
  
  .legal-info ul li {
    display: inline-block;  

    padding: 0px 2px 0px 2px;
  }
  
  .legal-info  small {
    color: rgba(89,88,68,0.7);
  }
  


.legal-info {
    display:flex;
    max-width: 900px;
    justify-content: center;
align-items:center;
}
.footer-content .titleElement .title {
  font-size: 22px;
}

@media screen and (max-width: 768px) {
  .footer{
    margin: 40px;
  }
  .footer-content {
    display: block;

    padding: 0px;
  }
  .footer-content .logo-container {

    justify-content: center;
    display: flex;}
  .footer-content .quick-links {
    margin-top: 40px ;
  }
  .footer .legal-info{
    display: block;
    
  }
 


}