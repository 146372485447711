  
  .link {
    margin-top: auto;
    text-decoration: none;
    color: #666;
    display: inline-block;
    font-weight: bold;
    transition: color 0.3s;
    font-size: 1rem;
  }
  
  .link:hover,
  .link:focus {
    color: #005299;
  }
  
  .animate-arrow {
    display: inline-flex;
    align-items: center;
    transition: transform 0.3s ease;
  }
  
  .animate-arrow svg {
    width: 10px;
    height: 7px;
    margin-left: 6px;
    transition: transform 0.3s ease;
  }
  
  .link:hover .animate-arrow,
  .link:focus .animate-arrow {
    transform: translateX(5px);
  }
  