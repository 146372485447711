/* Animations */
@media (prefers-reduced-motion: no-preference) {
  @keyframes animateAmbient {
    0% {
      transform: rotate(0deg) translateX(1em) rotate(0deg) scale(1.2);
    }
    100% {
      transform: rotate(360deg) translateX(1em) rotate(-360deg) scale(1.2);
    }
  }
}
