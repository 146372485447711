/* Common styles */
.header {
  margin: 0 auto;
  padding: 5px 0;

}

.header-component {
  margin: 0 auto;
  max-width: 90%;
  padding: 0 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  max-width: 200px;
}

nav.menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 700px;
}

.mainmenu {
  display: flex;
  align-items: center;
  gap: 20px;
}
a {
  text-decoration: none ;
}
.mainmenu a, .mainmenu p , .localization-form__currency {
  color: #595844;
  letter-spacing : 0.6px;
  transition: color 0.3s ease;
  font-size: 15px;
  opacity: 0.7;

}

.mainmenu p {
  cursor: default;
}



.menu-item {
  position: relative;
}

.menu-item:hover .dropdown-menu,
.menu-item:hover .dropdown-countries {
  display: block;
  opacity: 1;

  max-height: 200px;
  overflow-y: scroll;

  transform: translateY(0);
}

.header-icons {
  display: flex;
  gap: 20px;
}

.icon {
  font-size: 25px;
  cursor: pointer;
  color: #666666;
  opacity: 0.7;
  transition: color 0.3s ease;
}



.angle-icon {
  font-size: 18px;
  position: relative;
  top: 4px;
  transition: transform 0.3s ease;
  color: #666666;
  opacity: 0.7;
}

.angle-icon.open {
  transform: rotate(180deg);
}

/* Dropdown menu */
.dropdown-menu,
.dropdown-countries {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px;
  width: 110px;
  /* Increase width for more space */
  display: none;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(-10px);
  z-index: 9999;
}

.dropdown-menu ul,
.dropdown-countries ul {
  padding: 0;
  margin: 0;
}

.dropdown-menu li,
.dropdown-countries li {
  list-style: none;
  margin: 5px 0;
}

.dropdown-menu li a,
.dropdown-countries li a {
  color: #595844;
  transition: color 0.3s ease;
}

.dropdown-menu li a:hover,
.dropdown-countries li a:hover {
  color: #595844
;
}

.dropdown-container {
  position: relative;
}



.mobile-header , .mobile-menu  {
  display: none;
}



@media screen and (max-width: 768px) {
  .header {
    padding: 0;
  }

  .header-component {
    padding: 0 1rem;

  }

  .mobile-header,
  .extra {
    width: 25%;
  }

  .logo {
    width: 50%;
  }

  .menu,
  .submenu {
    display: none !important;
  }

  .mobile-header ,  .mobile-menu {
    display: block;
  }

  /* Mobile header styles */
  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .user-icon {
    display: none;
  }

  .mobile-menu-button {
    font-size: 24px;
    cursor: pointer;
    color: #666666;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease;
    z-index: 999;
    padding-top: 20px;
    overflow-y: auto;
  }

  .mobile-drawer {
    position: fixed;
    top: 0;
    left: -100%;
    width: 80%;
    height: 100vh;
    background-color: #fff;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease;
    z-index: 999;
  }


  .mobile-drawer.open {
    left: 0;
  }

  .mobile-drawer a {
    display: block;
    padding: 20px;
    color: #595844;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }

  .mobile-drawer a:hover {
    background-color: #f5f5f5;
  }

  .mobile-menu.open {
    left: 0;
    overflow-y: auto;
  }

  .mobile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .mobile-menu li {
    margin: 10px 0;
    padding: 0 20px;
  }

  .mobile-menu a {
    color: #595844;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .mobile-menu a:hover {
    color: #595844
;
  }

  .logo img {
    max-width: 150px;
  }

  .mobile-close-button {
    text-align: right;
  }

  .mobile-menu-items {
    padding: 0px 20px;
  }

  .mobile-menu-items .dropdown-menu,
  .mobile-menu-items .dropdown-container .dropdown-countries {
    width: 100%;
  }

  .mobile-menu-items a,
  .mobile-menu div {
    padding: 10px 20px;
    display: block;
    color: #595844;

    text-decoration: none;
    transition: background-color 0.3s ease;
    border-bottom: 1px solid #eee;

  }

  .mobile-menu a:last-child,
  .mobile-menu div:last-child {
    border-bottom: none;
  }
}