/* Styles for the video or image content */
.video-section__media {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.video-section__media video,
.video-section__media img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  background-color: white;
}

.play-button.hide {
  display: none;
}

.play-button svg {
  width: 40px;
  height: 40px;
  fill: white;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .title {
    font-size: 1.5rem;
  }
}
