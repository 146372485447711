
.textElement  .text-wrapper{

    max-width: 1120px;
    margin: 0 auto;
}
.textElement .text {
    margin: 30px 0;
    font-weight: 300;
    color: #666;
    font-size: 16px;
    /* letter-spacing: 0.06rem; */
      /* line-height: calc(1 + 0.8 / 1.0); */
  }
  @media screen and (max-width: 768px) {
 
    .textElement .text
    {
      font-size: 14px;
    }}
  