  
.cta-section-component  {
    overflow: hidden;
    /* height: 37rem; */
  }

  .cta-section-component h3 {
    font-size: 35px;
    font-weight: 800;
    color: var(--secondary-color); 
    margin: 0 0 20px 0;
  }
  
.cta-section-component  .ctaSection {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    overflow: hidden;
    position: relative;
  }
  
.cta-section-component  .banner-content {
    max-width: 90%;
    /* padding: 1rem 2rem; */
    text-align: center;
    background: var(--gradient-background);
    border-radius: 25px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
.cta-section-component h2.title {
  font-size: calc(1.3 * 1.4rem);
}

.cta-section-component  .bannerview {
  animation: animateAmbient var(--animation-duration) linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  }
  
.cta-section-component  .bannerview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
   


  
  

  @media screen and (max-width: 768px) {
    .cta-section-component .ctaSection {
      display: block;
    }
    .cta-section-component .heroSection{
      display: block;
      min-height: 80vh;
    }
    .cta-section-component h2.title {
      font-size: 35px;}

      .cta-section-component .bannerview {
        position: unset;
      }
      .cta-section-component .banner-content {
       
        padding: 3rem ;

      }
      
  }

  