.buttontertiary {
    display: inline-block;
  }
  
  .button-tertiary {
    background-color: transparent;
    color: white; /* Whitish text color */
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    box-shadow: 0px 0px 0px 2px rgba(166, 163, 172, 0.51), 0px 0px 0px 0px rgba(89, 85, 96, 0.3);
        transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, border-opacity 0.3s;
  }
  
  .button-tertiary:hover {
    box-shadow: 0px 0px 0px calc(2px + 2px) rgba(255, 255, 255, 0.1), 0px 0px 0px 2px rgba(255, 255, 255, 0.3);
  }
  